import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const QRCode = () => (
  <StaticImage
    alt="Download app on iOS or Android"
    src="../../images/download/qr-code.png"
    layout="fixed"
    placeholder="none"
    height={160}
    width={160}
  />
)

const IosDownloadFr = () => (
  <StaticImage
    alt="Download app on iOS App Store - Francais"
    src="../../images/download/ios-app-store-fr-ca.png"
    layout="constrained"
    placeholder="none"
    height={54}
    width={172}
  />
)
const IosDownloadEn = () => (
  <StaticImage
    alt="Download app on iOS App Store - English"
    src="../../images/download/ios-app-store-en.png"
    layout="constrained"
    placeholder="none"
    height={54}
    width={162}
  />
)
const PlayDownloadFr = () => (
  <StaticImage
    alt="Download app on Play Store - Francais"
    src="../../images/download/play-store-fr-ca.png"
    layout="constrained"
    placeholder="none"
    height={54}
    width={182}
  />
)
const PlayDownloadEn = () => (
  <StaticImage
    alt="Download app on Play Store - English"
    src="../../images/download/play-store-en.png"
    layout="constrained"
    placeholder="none"
    height={54}
    width={182}
  />
)

const Downloads = {
  QRCode,
  Ios: {
    fr: IosDownloadFr,
    en: IosDownloadEn,
  },
  Play: {
    fr: PlayDownloadFr,
    en: PlayDownloadEn,
  },
}

export default Downloads
