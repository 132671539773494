import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Component } from "../types"

type PhotoOrientation = "portrait" | "landscape"

export interface Photo extends Component {
  orientation: PhotoOrientation
}

const photo = (
  Component: Function,
  { orientation }: { orientation: PhotoOrientation }
): Photo => {
  Component["orientation"] = orientation

  return Component as Photo
}

const BraverTalk1Woman1 = photo(
  () => (
    <StaticImage
      alt="Woman at the Braver talk 1"
      src="../../images/photos/bt1-woman-1.jpg"
      placeholder="none"
    />
  ),
  { orientation: "landscape" }
)

const BraverTalk1Woman2 = photo(
  () => (
    <StaticImage
      alt="Woman at the Braver talk 2"
      src="../../images/photos/bt1-woman-2.jpg"
      placeholder="none"
    />
  ),
  { orientation: "portrait" }
)

const BraverTalk3Woman1 = photo(
  () => (
    <StaticImage
      alt="Woman at the Braver talk 3"
      src="../../images/photos/bt3-marielou-1.jpg"
      placeholder="none"
    />
  ),
  { orientation: "landscape" }
)

const ClinicianWoman1 = photo(
  () => (
    <StaticImage
      alt="Clinician Woman"
      src="../../images/photos/clinician-woman-1.jpg"
      placeholder="none"
    />
  ),
  { orientation: "landscape" }
)
const ClinicianMan1 = photo(
  () => (
    <StaticImage
      alt="Clinician Man"
      src="../../images/photos/clinician-man-1.jpg"
      placeholder="none"
    />
  ),
  { orientation: "landscape" }
)
const ClinicianWoman2 = photo(
  () => (
    <StaticImage
      alt="Clinician Woman"
      src="../../images/photos/clinician-woman-2.jpg"
      placeholder="none"
    />
  ),
  { orientation: "landscape" }
)
const ProfessionalWoman1 = photo(
  () => (
    <StaticImage
      alt="Clinician Woman"
      src="../../images/photos/professional-woman-1.jpg"
      placeholder="none"
    />
  ),
  { orientation: "landscape" }
)

const Photos = {
  BraverTalk1Woman1,
  BraverTalk1Woman2,
  BraverTalk3Woman1,
  ClinicianWoman1,
  ClinicianWoman2,
  ClinicianMan1,
  ProfessionalWoman1,
}

export default Photos
