import React from "react"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import styledMap from "styled-map"
import { getFromTheme as theme, hoverTransitions } from "../../utils/styles"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { H2, P } from "../common/typography"
import { ThemeColor } from "../../utils/colors"
import Illustrations from "../illustrations"
import Marker from "../markers"
import Icons from "../icons"
import HighlightedText from "../common/highlightedText"
import useBreakpoints from "../../utils/breakpoints"
import Downloads from "../images/downloads"
import Button from "../buttons"

const DownloadButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`

const DownloadLink = styled.a`
  > * {
    ${hoverTransitions("transform", "box-shadow")};
  }

  &:hover > * {
    transform: scale(1.01) translateY(-1px);
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.1);
  }
  &:active > * {
    transform: scale(1.01) translateY(0px);
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  text-align: center;
  max-width: 450px;
  position: relative;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface LaunchBlockMessages {
  title: string
}

interface LaunchBlockProps {
  messages?: LaunchBlockMessages
  color?: ThemeColor
}

const LaunchLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LaunchLinkWrapper = styled.div`
  align-self: flex-start;
  margin-top: 12px;
`

const defaultMessages: LaunchBlockMessages = {
  title: "blocks.download.title",
}
const NoopFader = styled.div``

const LaunchBlock = ({
  color = "secondary",
  messages = defaultMessages,
}: LaunchBlockProps) => {
  const intl = useIntl()
  const { breakpoints, currentBreakpoint } = useBreakpoints()
  const IosDownload = Downloads.Ios[intl.locale]
  const PlayDownload = Downloads.Play[intl.locale]
  const DownloadQRCode = Downloads.QRCode
  let Fader = Fade

  let decorationsScale = 1

  if (breakpoints[currentBreakpoint] < breakpoints.xlarge) {
    decorationsScale = 0.8
  }
  if (breakpoints[currentBreakpoint] < breakpoints.tiny) {
    decorationsScale = 0.6
  }
  if (breakpoints[currentBreakpoint] < breakpoints.medium) {
    Fader = NoopFader
  }

  return (
    <Wrapper>
      <Content>
        <Illustrations.Float
          vertical="centered"
          verticalOffset={100}
          horizontal="leftwards"
          horizontalOffset={20}
          style={{ position: "absolute", left: 0, top: "50%" }}
        >
          <Illustrations.ManTech_1.Fixed />
        </Illustrations.Float>

        <Marker color={color} Icon={Icons.Persons} large />

        <H2 fluid>
          <HighlightedText textId={messages.title} color={color} />
        </H2>

        <DownloadQRCode />

        <DownloadButtons>
          <Fader bottom>
            <DownloadLink
              href="https://brvr.io/ios-app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IosDownload />
            </DownloadLink>
          </Fader>
          <Fader bottom delay={100}>
            <DownloadLink
              href="https://brvr.io/android-app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <PlayDownload />
            </DownloadLink>
          </Fader>
          <Fader bottom delay={700} cascade={false}>
            <LaunchLink>
              <Button
                color="accent"
                text={intl.formatMessage({ id: "home.hero.launchWebApp" })}
                href="https://app.braver.net"
              />
            </LaunchLink>
          </Fader>
        </DownloadButtons>

        <Illustrations.Float
          vertical="upwards"
          verticalOffset={-100}
          horizontalOffset={-40}
          horizontal="rightwards"
          style={{ position: "absolute", right: 0, top: "30%" }}
        >
          <Illustrations.Handshake.Fixed />
        </Illustrations.Float>
      </Content>
    </Wrapper>
  )
}

export default LaunchBlock
